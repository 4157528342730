#emailCaptureForm {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.exit {
    position: absolute;
    right: 2%;
    top: 2%;
    cursor: pointer;
}

.exit-img {
    width: 70%;
}

.error-text {
    letter-spacing: 0.4px;
    color: #CE1B04;
    display: none;
    margin-top: 30px;
    position: relative;
    margin-bottom: -25px;
    left: 5px;
}

.success {
    display: none;
    text-align: center;
}

#campaign_id,
#form_name {
    display: none;
}

@media (min-width: 320px) and (max-width: 480px) {
    .body {
        top: 50%;
        left: 0;
    }
}